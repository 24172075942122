import logo from './logo.svg';
import React, { useEffect } from 'react';
import MyBook from './components/page-flip';
import './App.css';

function App() {

  useEffect(() => {
    const disableContextMenu = (e) => {
      e.preventDefault();
    };

    // Add the event listener when the component mounts
    document.addEventListener('contextmenu', disableContextMenu);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('contextmenu', disableContextMenu);
    };
  }, []);

  return (
    <div className="App">
      <MyBook/>
    </div>
  );
}

export default App;
