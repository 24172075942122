import HTMLFlipBook from 'react-pageflip';
import React, { useEffect, useRef, useState } from 'react';
import { motion } from "framer-motion"

const Logo = () => {
  return (
    <div className="logo">
      {/* <img className="logo-img" src="https://res.cloudinary.com/df5etts2d/image/upload/v1688503763/Arrows_Logo-03_juj87o.png"></img> */}
      WELCOME
    </div>
  )
}

const MyBook = () => {
  <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.5 }}
  />
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const book = useRef();

  return (
    <div className="wrapper">
      <Logo />
      <motion.div
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}>
        <div className="container">
          <HTMLFlipBook
            className="book"
            showCover={true}
            width={760}
            height={580}
            maxShadowOpacity={0.2}
            ref={book}
            flippingTime={600}
            onFlip={(e) => setPageNumber(e.data)}
          >
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698803/GW/GW_1_rvzxmd.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698804/GW/GW_2_fthnfp.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698804/GW/GW_3_uhihpn.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698803/GW/GW_4_jfaoq2.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698803/GW/GW_5_pp8yjc.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698804/GW/GW_6_guoz5h.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698804/GW/GW_7_trrtxg.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698805/GW/GW_8_ijnwys.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698804/GW/GW_9_cdkfpy.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698804/GW/GW_10_rr52q7.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698804/GW/GW_11_wzied1.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698805/GW/GW_12_k7ywfe.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698804/GW/GW_13_idkfxc.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698805/GW/GW_14_mch5zz.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698805/GW/GW_15_vjxngf.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698805/GW/GW_16_nbddoy.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698805/GW/GW_17_wtfqi7.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698805/GW/GW_18_iyad7y.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698805/GW/GW_19_ilzrfb.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698806/GW/GW_20_et2etl.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698806/GW/GW_21_gfzwhw.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698805/GW/GW_22_uftfad.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698806/GW/GW_23_jpae3e.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698806/GW/GW_24_px7ofi.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698806/GW/GW_25_edetmi.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698806/GW/GW_26_xoyz8m.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698806/GW/GW_27_nzl5gn.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698806/GW/GW_28_dxupvw.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698807/GW/GW_29_tttfed.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698806/GW/GW_30_k2wdzj.jpg"></img></div>
            <div className="demoPage"> <img class="page" src="https://res.cloudinary.com/df5etts2d/image/upload/v1689698807/GW/GW_31_y2ewkw.jpg"></img></div>
          </HTMLFlipBook>
          <button onClick={() => book.current.pageFlip().flipNext()}><img className="button" src="https://res.cloudinary.com/df5etts2d/image/upload/v1688506089/Arrows_Logo-01_klc42v.png"></img></button>
          <button onClick={() => book.current.pageFlip().flipPrev()}><img className="button-left" src="https://res.cloudinary.com/df5etts2d/image/upload/v1688506089/Arrows_Logo-01_klc42v.png"></img></button>
          <button onClick={() => console.log(pageNumber)}>Test</button>
        </div>
      </motion.div>
    </div>
  );
}

export default MyBook